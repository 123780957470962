import { datadogRum } from '@datadog/browser-rum'
import Base from 'core/pages/Shipping/WithEncoding/WithEncodingReading'
import {
  ConfigurationDetail,
  EncodingValidationRequest,
  IdentifierType,
  ProductInfoField,
  TmrTag
} from 'stylewhere/api'
import { EncodingExtensions } from 'stylewhere/extensions'
import { AppStore, RfidReader } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { getTimeoutValidateTags, sleep } from 'stylewhere/shared/utils'

export default class WithEncodignReading extends Base {
  isDecodeNfc = false

  onStopCallbackIdentifierCheck = (tmpEncoding: EncodingValidationRequest) => {
    if (
      tmpEncoding &&
      tmpEncoding.identifiers &&
      // nfc collision
      (tmpEncoding.identifiers.some((idt) => idt._status === 'NFC_COLLISION') ||
        // nfc auth timeout
        tmpEncoding.identifiers.some(
          (idt) => idt._status === 'ERROR' && idt._error && [__(T.error.authentication_timeout)].includes(idt._error)
        ) ||
        // uhf errors
        tmpEncoding.identifiers.some((idt) => idt.identifierType === 'UHF_TAG' && idt._status === 'ERROR'))
    ) {
      tmpEncoding.identifiers.forEach((element) => {
        if (!element._status || element._status === 'PROCESSING') element._status = 'SKIPPED'
      })
      return false
    }

    // default
    return (
      tmpEncoding && tmpEncoding.identifiers && !tmpEncoding.identifiers.every((idt) => idt._status === 'CONFIRMED')
    )
  }

  onTagRead = async (tag: TmrTag) => {
    //added a sleep to destroy correctly the timers or wait isEncoding flag
    await sleep(Math.random() * 500)

    const { encodingValidation, encodingValidationResponse, encodingCreateResponse, associationStatus } = this.state
    if (!encodingValidation || !encodingValidation.identifiers) return
    if (this.isEncoding || this.isDecodeNfc) {
      this.removeTagReadFromReader([tag])
      return
    }
    if (!this.additionalOnTagReadCheck(tag)) return
    // if time to validate is 0 then set isEncoding to true immediately for not have validated in parallel
    const timeoutValidateTags = getTimeoutValidateTags(this.embeddedEncodingOperation, this.getIgnoreUnknownTags())
    // if (timeoutValidateTags !== undefined && timeoutValidateTags === 0) {
    this.isEncoding = true
    // }
    try {
      RfidReader.setAutomaticStop(false)
      RfidReader.stopTimer()
      this.clearTimerReader()
      this.clearTimer()

      const nfcRequiresMutualAuth =
        this.embeddedEncodingOperation.attributes['nfc-requires-mutual-authentication'] &&
        this.embeddedEncodingOperation.attributes['nfc-requires-mutual-authentication'] === 'true' &&
        encodingValidationResponse?.configuration?.details.some((conf) => conf.identifierType === 'NFC_TAG')
      if (tag.uid && nfcRequiresMutualAuth) {
        this.isDecodeNfc = true
        this.setState({ processing: 'nfcDecryption' })
      }
      await EncodingExtensions.onTagRead(encodingValidation!, tag, this.embeddedEncodingOperation)

      // check extra UHF tag
      if (encodingValidation.identifiers.filter((id) => id.identifierType === 'UHF_TAG').length > 1) {
        const newEncodingValidation = {
          ...encodingValidation,
          identifiers: encodingValidation.identifiers.filter((id) => id.identifierType !== 'UHF_TAG')
        }
        const uhfIdentifier = encodingValidation.identifiers.find((id) => id.identifierType === 'UHF_TAG')!
        uhfIdentifier._error = __(T.custom.tooManyTags)
        uhfIdentifier._status = 'ERROR'
        newEncodingValidation.identifiers.push(uhfIdentifier)
        this.setState({ encodingValidation: newEncodingValidation, associationStatus: 'ERROR' })
        this.stopAntenna()
        this.clearTimer()
        this.clearTimerReader()
        this.isEncoding = false
        return
      }

      // check NFC errors
      const nfcError = encodingValidation.identifiers.some(
        (id) => id.identifierType === 'NFC_TAG' && id._status === 'ERROR'
      )
      if (tag.uid && nfcRequiresMutualAuth) {
        console.log('NFC decoded as ', tag.uid)
        this.setState(
          {
            processing: false,
            associationStatus: nfcError ? 'ERROR' : associationStatus
          },
          () => {
            if (nfcError) {
              this.isEncoding = false
              this.isDecodeNfc = false
              this.stopAntenna()
              this.clearTimer()
              this.clearTimerReader()
            }
          }
        )
      }
      const nfcCollision = encodingValidation.identifiers.some(
        (id) => id.identifierType === 'NFC_TAG' && id._status === 'NFC_COLLISION'
      )
      this.setState({ encodingValidation }, () => {
        if (nfcCollision) {
          console.log('NFC collision detected')
          this.setState({ processing: false, associationStatus: 'ERROR' })
          this.isEncoding = false
          this.isDecodeNfc = false
          this.stopAntenna()
          this.clearTimer()
          this.clearTimerReader()
          this.datadogSession?.errors?.push({ error: 'nfcCollision' })
          datadogRum.addAction('err_nfcCollision', this.datadogSession)
        } else {
          if (this.datadogSession && !encodingCreateResponse?.success) {
            if (tag.uid) {
              if (!this.datadogSession.nfcRead) this.datadogSession.nfcRead = []
              this.datadogSession.nfcRead.push({
                offsetMs: Date.now() - this.datadogSession.startTime.getTime(),
                nfcRead: tag.uid
              })
              datadogRum.addAction('nfcRead', this.datadogSession)
            } else if (tag.epc) {
              if (!this.datadogSession.uhfRead) this.datadogSession.uhfRead = []
              this.datadogSession.uhfRead.push({
                offsetMs: Date.now() - this.datadogSession.startTime.getTime(),
                uhfRead: tag.epc
              })
              datadogRum.addAction('uhfRead', this.datadogSession)
            }
          }
          this.onTagReadTimer()
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ processing: false })
      this.isEncoding = false
      this.isDecodeNfc = false
    }
  }

  getErrorCodesToWhichStopAntenna = () => {
    return ['EXTRA_TAG', 'WRONG_PRODUCT', 'WRONG_MADEIN', 'UNKNOWN_TAG']
  }

  confirmButtonEnabled = () => {
    if (this.state.autoConfirmParcel) {
      return this.state.initReaderTags.length > 0
    }
    return this.parcelIsSuitableForConfirmation()
  }

  getFilteredIdentifiersToRender = (identifiers: ConfigurationDetail[]) => {
    if (AppStore.emulation) return identifiers
    return identifiers.filter((idt) => idt.role !== 'SERIAL')
  }

  getHideIdentifierByType = (): IdentifierType[] | undefined => {
    return ['NFC_TAG', 'UHF_TAG']
  }

  getHideIdentifierCodeByType = (): IdentifierType[] | undefined => {
    return ['NFC_TAG', 'UHF_TAG']
  }

  getIdentifiersLabelsByType: () => Partial<Record<IdentifierType, string>> | undefined = () => {
    return { ['UHF_TAG']: __(T.misc.external_tag), ['NFC_TAG']: __(T.misc.internal_tag) }
  }

  getDisableRetry() {
    return true
  }

  getProductInfoFields: () => ProductInfoField[] | undefined = () => {
    return []
  }

  getHideIdentifierZones: () => boolean = () => {
    return true
  }

  render() {
    return super.render()
  }
}
