import { datadogRum } from '@datadog/browser-rum'
import Base, { ShippingState } from 'core/pages/Shipping/ShippingReading'
import { DecodedItem, IdentifierType, ProductInfoField, Shippings } from 'stylewhere/api'
import { FormSchemaForm } from 'stylewhere/components'
import { BarcodeModeOptions } from 'stylewhere/components/AntennaButton'
import { ShippingExtensions } from 'stylewhere/extensions'
import { AppStore, FormSchemaData, getDataFromSchema, OperationReadingProps, RfidReader } from 'stylewhere/shared'
import { CustomError } from 'stylewhere/shared/errors'
import { __, T } from 'stylewhere/shared/i18n'
import { filterIgnoredItems, getChecklistItemsCount, showToastError, sleep } from 'stylewhere/shared/utils'
import { v4 as generateUid } from 'uuid'

interface CustomShippingState extends ShippingState {
  renderIteration: number
  formSubmitted: boolean
}

export default class ShippingReading extends Base<OperationReadingProps<CustomShippingState>, CustomShippingState> {
  state: CustomShippingState = {
    ...this.state,
    renderIteration: 0,
    formSubmitted: false
  }

  alertOnNfcTagRead =
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.operation.attributes['alertOnNfcTagRead'] && this.operation.attributes['alertOnNfcTagRead'] === 'true'

  singleShippingScreen =
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.operation.attributes['singleShippingScreen'] && this.operation.attributes['singleShippingScreen'] === 'true'

  schema = ShippingExtensions.formSchema(this.operation)

  kickBackCondition = () => {
    return !this.singleShippingScreen && !this.locationState.formData
  }

  disableFormData = () => {
    return this.singleShippingScreen
  }

  _goBack = this.goBack.bind(this)

  goBack = () => {
    if (this.singleShippingScreen) {
      this.goDashboard()
    } else {
      this._goBack()
    }
  }

  decodedItemCheck = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    if (this.alertOnNfcTagRead && Object.keys(itemMapFromReader).some((tagCode) => tagCode.startsWith('E0'))) {
      showToastError(__(T.custom.nfcDetectedPleaseUseProductControl), __(T.error.error), true)
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      return false
    }
    return true
  }

  onFormSubmit = async (formData: FormSchemaData) => {
    await sleep(500)
    let parcelCode
    const { autostartAntenna, barcodeItemReading } = this.state
    this.setState({ formData, formSubmitted: true }, async () => {
      const payloadStartParcel: any = getDataFromSchema(formData, this.schema)
      try {
        parcelCode = payloadStartParcel.parcelCode
        const decodeRequest = this.getDecodeRequest()
        RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, decodeRequest)
        const parcel = await Shippings.startParcel(
          this.operation,
          payloadStartParcel,
          this.operation.startParcelWithAsn
        )

        const entriesItem: DecodedItem[] = []
        let tagsToAdd: string[] = []
        if (parcel && parcel.parcelEntries && parcel.parcelEntries.length > 0) {
          parcel.parcelEntries.map((entry) => {
            if (entry.item && entry.item !== null) {
              const uhfTag = entry.item.identifiers.filter((e) => e.type === 'UHF_TAG').flatMap(({ code }) => code)
              tagsToAdd = tagsToAdd.concat(uhfTag)
              entriesItem.push({
                item: entry.item,
                statuses: [],
                identifierCode: uhfTag.length > 0 ? uhfTag[0] : ''
              })
            }
          })
        }

        this.setState({ parcel, items: entriesItem, initReaderTags: tagsToAdd })

        if (autostartAntenna && !barcodeItemReading) {
          this.initializeDataDogSession()
          this.antennaRef.current.startReader()
        }
      } catch (err) {
        if (
          await ShippingExtensions.checkPackingUnpack(
            err as any,
            parcelCode,
            this.operation,
            () => this.onFormSubmit(formData),
            () => this.setState({ renderIteration: this.state.renderIteration + 1 })
          )
        )
          return
        if (err instanceof CustomError) {
          showToastError(
            `${CustomError.interpolateMessage(err.message, payloadStartParcel)}. [${err.errorCode}]`,
            __(T.error.error),
            this.isModal,
            () => this.setState({ renderIteration: this.state.renderIteration + 1 })
          )
          return
        }
        showToastError(err, 'Start Parcel Error', this.isModal)
      }
    })
  }

  antennaStartButtonEnabled = () => {
    return !this.singleShippingScreen || this.state.formSubmitted
  }

  antennaClearButtonVisible = () => {
    return false
  }

  resetScreen = async () => {
    await this.antennaRef.current.stopReader()
    RfidReader.clear()
    this.setState({
      parcel: undefined,
      asn: undefined,
      items: [],
      formData: {},
      tags: undefined,
      formSubmitted: false,
      renderIteration: this.state.renderIteration + 1
    })
  }

  canAutoStartAntenna = () => {
    return !this.singleShippingScreen
  }

  overrideProductInfoFields: () => ProductInfoField[] | undefined = () => {
    return []
  }

  confirmButtonEnabled = () => {
    if (this.state.autoConfirmParcel) {
      return this.state.initReaderTags.length > 0
    }
    if (
      this.operation.canConfirmWithMissing === 'no' &&
      this.operation.canConfirmWithOverQuantity === 'no' &&
      this.operation.canConfirmWithUnexpected === 'no'
    )
      return this.parcelIsSuitableForConfirmation()
    return true
  }

  getHideIdentifierZones: () => boolean = () => {
    return true
  }

  getHideIdentifierCodeByType = (): IdentifierType[] | undefined => {
    return ['NFC_TAG', 'UHF_TAG']
  }

  getDisableModalRowCounters = () => {
    return true
  }

  _afterConfirmSuccess = this.afterConfirmSuccess.bind(this)

  afterConfirmSuccess = async () => {
    if (this.singleShippingScreen) {
      if (['hideSuccessModal', 'changeInput', 'disabled'].includes(this.operation.postConfirmAction)) {
        this.setState({ renderIteration: this.state.renderIteration + 1 }, this.resetScreen)
      } else {
        //keepInput not managed
      }
    } else {
      this._afterConfirmSuccess()
    }
  }

  upperSideBarComponent: () => JSX.Element = () => {
    if (!this.singleShippingScreen) return <></>
    return (
      <FormSchemaForm
        key={this.state.renderIteration}
        schema={this.schema}
        submitText={this.state.formSubmitted ? undefined : __(T.misc.next)}
        onSubmit={this.onFormSubmit}
        onCancel={this.resetScreen}
        cancelText={__(T.misc.clear)}
        formContext="start"
        disabled={this.state.formSubmitted}
        operation={this.operation}
        style={{ paddingBottom: 20, width: '100%' }}
        disabledSpacerAfterFields
      />
    )
  }

  getBarcodeModeOptions: () => BarcodeModeOptions | undefined = () => {
    const { items, parcel } = this.state
    const itemsCount = items.filter(filterIgnoredItems).length
    const { expecteds, overQty, unexpecteds } = getChecklistItemsCount(
      items,
      this.operation,
      ShippingExtensions,
      parcel?.checklist,
      parcel?.products
    )
    return {
      displayMode: 'inputReplacesAntennaButton'
      // displayMode: 'modal',
      // modalOptions: {
      //   validateMode: 'itemByItem',
      //   reOpenAfterSubmit:
      //     itemsCount < expecteds && !overQty && !unexpecteds && !items.some((item) => item.item?.status === 'error'),
      // },
    }
  }

  render(): JSX.Element {
    return super.render()
  }
}
